import { env } from 'src/env.mjs';
import { Vertical } from './Verticals';
import { ErrorProps } from 'app/error/page';

const moshBasePath = env.NEXT_PUBLIC_MOSH_BASE_PATH;
const moshyUserPortalPath = env.NEXT_PUBLIC_USER_PORTAL_PATH;

const Routes = {
    QUIZZES: `/quizzes`,
    REVIEWS: 'https://au.trustpilot.com/review/getmosh.com.au',
    CONTACT_US: `${moshBasePath}/contact`,
    CREATE_ACCOUNT: '/create-account',
    WELCOME: '/create-account/welcome',
    VERIFY_EMAIL: '/verify-email',
    VERIFY_PHONE: '/verify-phone',
    LOGIN: '/login',
    QUIZ_CONSULT: '/quiz_consult',
    QUIZ_CONSULT_BOOKING: '/quiz_consult/booking',
    MEDICAL_SUPPORT: '/medical-support',
    MEDICATIONS: (medicationId: string) =>
        `/medical-support/medications/${medicationId}`,

    WEIGHT_LOSS_LANDING_PAGE: '/weight-loss',
    WEIGHT_LOSS_INITIAL_QUIZ: `/initial/weight_loss`,
    WEIGHT_LOSS_RENEWAL_QUIZ: `/renewal/weight_loss`,
    WEIGHT_LOSS_PLAN: `/wl_treatment_plan`,
    WEIGHT_LOSS_CHECKOUT: (quizToken?: string) =>
        quizToken ? `/wl_checkout?quiz_token=${quizToken}` : `/wl_checkout`,

    SKIN_LANDING_PAGE: `/skin`,
    SKIN_INITIAL_QUIZ: `/initial/skin`,
    SKIN_RENEWAL_QUIZ: `/renewal/skin`,
    SKIN_PLAN: `/skin_treatment_plan`,
    SKIN_CHECKOUT: (quizToken?: string) =>
        quizToken
            ? `/skin_checkout?quiz_token=${quizToken}`
            : ` /skin_checkout`,

    CHECKOUT: (vertical: Vertical, quizToken: string) =>
        `/checkout/${vertical.toLowerCase().replace(' ', '-')}?quiz_token=${quizToken}`,

    SIGN_IN: (redirectUrl?: string) =>
        redirectUrl
            ? `/login?redirect_url=${encodeURIComponent(redirectUrl)}`
            : `/login`,
    SIGN_OUT: `${moshyUserPortalPath}/users/sign_out`,
    TERMS_OF_USE: '/terms',
    PRIVACY_POLICY: '/privacy',
    NOTIFICATIONS: `${moshyUserPortalPath}/notifications`,
    SUPPORT: `${moshyUserPortalPath}/support`,
    SITEMAP: '/sitemap',
    ABOUT_US: '/about-us',
    COMMUNITY: '/join-our-community',
    INSTAGRAM: 'https://www.instagram.com/moshyhealth',
    FACEBOOK: 'https://www.facebook.com/moshy.health',
    FACEBOOK_COMMUNITY: 'https://www.facebook.com/groups/moshycommunity',
    QIP: 'https://www.qip.com.au/for-consumers/',
    HOME: '/',
    ERROR: (params: ErrorProps['searchParams']) =>
        `/error?error_type=${params.error_type}${params.error_title ? `&error_title=${encodeURIComponent(params.error_title)}` : ''}${params.error_message ? `&error_message=${encodeURIComponent(params.error_message)}` : ''}`,
    BLOG: '/blog',
    MOSH_HOME: moshBasePath,
    SLICE_SIMULATOR: '/prismic/slice-simulator',
    FAQ: 'https://support.getmosh.com.au/moshy',
};

export default Routes;
